import * as React from "react"
import LinkButton from "../elements/LinkButton";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "../../utils/html-parser.options"


const DiscoverHomeComponent = ({ title, content, urlButton, labelButton, image, imageOnTheLeft, buttonLinkIsExternal }) => {
    return (
        <section className="flex flex-1 flex-col flex-start section section-title lg:px-4 mt-32">
            <h2>
                {title}
            </h2>

            <div className={`flex gap-20 md:flex-wrap${imageOnTheLeft ? ' flex-row-reverse' : ''} md:flex-col-reverse md:gap-5`}>
                <div className="md:w-full w-2/3 mx-auto">
                    {parse(content)}
                    <div className="mt-10 justify-self-center self-center">
                    <LinkButton to={urlButton} external={buttonLinkIsExternal} >{labelButton}</LinkButton>
                    </div>
                    
                </div>
                <div className="md:w-2/3 w-1/3 mx-auto">
                    <img className="flex-grow" src={image} alt={`Thumbnail of ${title}`} width="325" height="400" />
                </div>
            </div>
            
        </section>
    )
}

export default DiscoverHomeComponent;