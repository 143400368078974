import * as React from "react"
import "../../styles/title.css"
import { useI18next } from 'gatsby-plugin-react-i18next';
import parse from "../../utils/html-parser.options"

const TitleComponent = ({ description , hideEuroWeek = false }) => {
    const { language } = useI18next();
    const renderTitle = (lang) => {
        switch (lang) {
            case 'fr':
                return (<h1 className="flex flex-col text-center gap-1 items-center m-0">
                    {hideEuroWeek ? "" : (<span className="m-0 uppercase font-normal tracking-widest text-week lg:text-3xl lg:tracking-wide lg:leading-9">Semaine Européenne du</span>)}
                    <div className="flex gap-x-4 flex-wrap justify-center">
                        <span className="numeric-title text-headline lg:text-4xl">Numérique</span>
                        <span className="responsable-title text-headline lg:text-4xl">Responsable</span>
                    </div>

                </h1>);
            case 'en':
                return (<h1 className="m-0 text-center">
                    <div className="flex items-center justify-center gap-x-4 flex-wrap">
                    {hideEuroWeek ? "" : (<span className="week-title text-week lg:text-3xl">European</span>)}
                        <span className="responsable-title text-headline lg:text-4xl">Sustainable</span>
                    </div>
                    <div className="flex items-center  justify-center gap-x-4 flex-wrap">
                        <span className="numeric-title text-headline lg:text-4xl">IT</span>
                        {hideEuroWeek ? "" : (<span className="week-title text-week lg:text-3xl">Week</span>)}
                    </div>
                </h1>);
            case 'de':
                return (<h1 className="flex flex-col gap-1 items-center m-0 text-center">
                    <div className="flex items-center justify-center gap-x-4 flex-wrap">
                    {hideEuroWeek ? "" : (<span className="week-title text-week lg:text-3xl">EUROPÄISCHE</span>)}
                        <span className="responsable-title text-headline lg:text-4xl">Sustainable</span>
                    </div>
                    <div className="flex items-center  justify-center gap-x-4 flex-wrap">
                        <span className="numeric-title text-headline lg:text-4xl">IT</span>
                        {hideEuroWeek ? "" : (<span className="week-title text-week lg:text-3xl">Woche</span>)}
                    </div>
                </h1>);
            default:
                return (<div></div>);
        }
    }
    return (
        <section className="flex flex-col container mx-auto px-4 my-20">
            {renderTitle(language)}
            <div className="whitespace-normal text-2xl mt-2 lg:text-xl">
                {parse(description)}
            </div>
        </section>
    )
}

export default TitleComponent;