import * as React from "react"
import "../../styles/video.css"
import LinkButton from "../elements/LinkButton";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

const LatestNewsHomeComponent = ({ newsArticles, ressourcesArticles }) => {
  const { t } = useTranslation();
  const articles = newsArticles.concat(ressourcesArticles);
  return (
    <section className="flex flex-col flex-start section section-title mt-32 lg:px-4">
      <h2>
        {t("latest_news_subheader")}
      </h2>
      <div className="flex gap-20 md:flex-wrap">
        <div className="inline-block">
          <Link  to={"/article/" + articles[0]?.slug}>
            <img loading="lazy" width="766,32px" height="430,23px" alt={`Thumbnail of ${articles[0]?.title}`} className="flex-grow image-video" src={articles[0]?.thumbnailImage}>


            </img>
            </Link>
            <div className="w-0 min-w-full">
              <Link className="flex flex-col gap-4 mt-5 mb-8" to={"/article/" + articles[0]?.slug}>
                <span className="item-title">{articles[0]?.title}</span>
                <span class="text-orange-nr">{t("news_element")}</span>
                <span>{articles[0]?.shortDescription}</span>
              </Link>
              <LinkButton to="/commitment">{t("all_news")}</LinkButton>

            </div>

        </div>
        <div className="grid grid-rows-2 justify-between gap-5">
          <Link className="flex flex-col gap-3" to={"/article/" + articles[1]?.slug}>
            <img loading="lazy" className="flex-grow image-subvideo" width="325" height="229" alt={`Thumbnail of ${articles[1]?.title}`} src={articles[1]?.thumbnailImage} ></img>
            <span className="item-title w-0 min-w-full">{articles[1]?.title}</span>
            <span class="text-orange-nr">{t("ressource_element")}</span>
          </Link>
          <Link className="flex flex-col gap-3" to={"/article/" + articles[2]?.slug}>
            <img loading="lazy" className="flex-grow image-subvideo" width="325" height="229" alt={`Thumbnail of ${articles[2]?.title}`} src={articles[2]?.thumbnailImage} ></img>
            <span className="item-title w-0 min-w-full">{articles[2]?.title}</span>
            <span class="text-orange-nr">{t("ressource_element")}</span>
          </Link>
          <LinkButton to="/ressources" className="mt-3" >{t("all_ressources")}</LinkButton>

        </div>
      </div>
      
    </section>
  )
}

export default LatestNewsHomeComponent;