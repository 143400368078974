import * as React from "react"
import DiscoverHomeComponent from "./discover"
import FollowUsComponent from "./follow-us"
import TitleComponent from "./title"
import LatestNewsHomeComponent from "./latest-news"
import Layout from "../layout/layout"
import SEOComponent from '../seo'
import { useTranslation } from "gatsby-plugin-react-i18next";

// markup
const HomeComponent = ({ data, hideEuroWeek = false }) => {
  const { t } = useTranslation();
  const siteContent = data.siteContent
  const homeContent = data.homeContent.nodes[0]
  return (
    <>
     <TitleComponent description={homeContent.description} hideEuroWeek={hideEuroWeek}></TitleComponent>
        {!homeContent.hideEdito ? (<DiscoverHomeComponent
          title={homeContent.editoTitle}
          content={homeContent.editoContent}
          urlButton={homeContent.editoUrlButton}
          labelButton={homeContent.editoLabelButton}
          image={homeContent.editoImage}
          imageOnTheLeft />) : ""}

          {
            !homeContent.hideCommit ? ( <DiscoverHomeComponent
              title={homeContent.commitTitle}
              content={homeContent.commitContent}
              urlButton={homeContent.commitDocument}
              labelButton={homeContent.commitLabelButton}
              image={homeContent.commitImage}
              imageOnTheLeft />) : ""          
          }

          {
            !homeContent.hideLatestNews ? ( <LatestNewsHomeComponent newsArticles={data.newsArticle.nodes} ressourcesArticles={data.ressourcesArticles.nodes}></LatestNewsHomeComponent>) : ""
          }
         
       
        <FollowUsComponent
          facebookLink={siteContent.facebookURL}
          instagramLink={siteContent.instagramURL}
          twitterLink={siteContent.twitterURL}
          linkedinLink={siteContent.linkedinURL}
        ></FollowUsComponent>
    </>

  )
}

export default HomeComponent