import * as React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next";
import SocialComponent from "../socials"


const FollowUsComponent = ({ facebookLink, instagramLink, linkedinLink, twitterLink }) => {
  const { t } = useTranslation()
  return (
    <section className="flex flex-col flex-start section section-title mt-32 lg:px-4">
      <h2>
        {t("follow_us")}
      </h2>
      <div>
        <p>{t("sub_newsletter")}</p>
        <SocialComponent className="flex gap-20 mt-10 text-5xl lg:gap-5" fontSize="inherit"
          facebookURL={facebookLink}
          instagramURL={instagramLink}
          linkedInURL={linkedinLink}
          twitterURL={twitterLink}
        >
        </SocialComponent>
      </div>
    </section>
  )
}

export default FollowUsComponent;